@keyframes slide-down {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, calc(50vh - 12px));
  }
}

@keyframes scale-up {
  from {
    position: absolute;
    top: 50vh;
    transform: scale(1);
  }
  to {
    top: 50vh;
    transform: scale(1.75);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.75);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.75);
  }
}

.logo {
  position: absolute;
}

.logo.signed-in {
  animation: slide-down 0.5s ease-in-out 0.2s forwards,
    scale-up 0.5s ease-in-out 0.75s forwards, pulse 2s ease-in-out 1.5s infinite;
}

.form {
  width: 100%;
}

.grey-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral700) !important;
  letter-spacing: 0.1px;
  text-align: center;
}

.underline-medium {
  fontsize: 14px;
  lineheight: 20px;
  color: var(--neutral900) !important;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: var(--neutral900) !important;
  text-decoration-thickness: 1.5px;
  cursor: pointer;
  user-select: none;
}

.button {
  width: 100%;
  user-select: none;
}

.password-checker-hidden {
  display: none;
  opacity: 0;
  transform: translate(0, -12px);
  height: 0;
  margin-bottom: 0;
  transition: all 0.05s ease-in-out;
}

.password-checker-visible {
  display: block;
  opacity: 1;
  height: auto;
  margin-bottom: 32px;
  transform: translate(0, 0);
  transition: all 0.5s ease-in-out;
}

.hidden {
  display: none;
  opacity: 0;
  transform: translate(0, -12px);
  height: 0;
  transition: all 0.05s ease-in-out;
}

.visible {
  display: block;
  opacity: 1;
  height: auto;
  transform: translate(0, 0);
  transition: all 0.5s ease-in-out;
}
